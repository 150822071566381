'use strict';
app.factory('managementService', ['$rootScope', 'broadcastService', 'displayService', '$ocLazyLoad', 'usageAnalyticsService', 'formFilesCacheService', 'userService', function ($rootScope, broadcastService, displayService, $ocLazyLoad, usageAnalyticsService, formFilesCacheService, userService)
{
    let managementService = {};

    let States = {
        Requested: 1,
        Opening: 2,
        Opened: 3,
        Closed: 4,
        Hide: 5
    };

    let Tabs = [];

    //replacement for new method
    managementService.requestForm = function (request)
    {
        _MarkMissingTabAsClosed();

        if (_ValidateRequest(request))
        {
            let _request = _FormatRequest(request);

            const isCustomViewContent = _request.RequestMenuKey === 1096 && !_.isEmpty(_request.RequestDetail.parameters);
            usageAnalyticsService.postUsageAnalytics(isCustomViewContent ? "SYT_CustomStoreProc" : "SYC_MenuHdr", _request.RequestMenuKey);

            formFilesCacheService.load(_request.RequestHTML, _request.RequestJS).then(function ()
            {
                _ProcessRequest(_request);

                //store last request to reopen it on reload :)
                setTimeout(() =>
                {
                    if ($rootScope.isLocal === true && window && window.sessionStorage)
                    {
                        window.sessionStorage.lastOpen = JSON.stringify(request);
                    }
                }, 0);
            }).catch(function (e)
            {
                console.error("load form files error", e)
                broadcastService.broadcast("openWinReminder", "loadFormFilesError");
            });
        }
        else if (_.isEmpty(displayService.getFormInformation()) && _.isEmpty(displayService.getReportInformation())) // when both menus are empty, probably is refreshing data.
        {
            broadcastService.broadcast("openWinReminder", "refreshingDataSourceError");
        }
        else
        {
            console.log("Invalid Request/Inactive Menu/Insufficient Permission :");
            console.log(request);

            broadcastService.broadcast("openWinReminder", "invalidform");
        }
    };

    function _ValidateRequest(request)
    {
        if (typeof request === "undefined" || request === null)
        {
            return false
        }
        else
        {
            //patch back missing information
            if (typeof request.requestedForm === "string" && request.requestedForm !== "reportviewer")
            {
                if (typeof request.MenuKey === "number" && request.MenuKey > 0)
                {
                    let MenuInfo = displayService.getFormInformation(true, request.MenuKey);

                    if (typeof MenuInfo !== "undefined" && MenuInfo != null && typeof MenuInfo.length !== "undefined" && MenuInfo.length > 0)
                    {
                        //still has access
                        request.name = MenuInfo[0].DispName;
                        request.url = MenuInfo[0].MenuUrl;
                        request.jsUrl = MenuInfo[0].JsControllerUrl;
                    }
                    else
                    {
                        //no longer has access, blocked
                        return false;
                    }
                }
            }
            else if (typeof request.requestedForm === "string" && request.requestedForm === "reportviewer")
            {
                if (typeof request.MenuKey === "number" && request.MenuKey > 0)
                {
                    //normal report with MenuKey for example Balance Sheet
                    let ReportInfo = displayService.getReportInformation(false, request.MenuKey);

                    if (typeof ReportInfo !== "undefined" && ReportInfo != null && typeof ReportInfo.length !== "undefined" && ReportInfo.length > 0)
                    {
                        //still has access
                    }
                    else
                    {
                        //maybe is printed from form where MenuKey is actually parent form's MenuKey
                        let MenuInfo = displayService.getFormInformation(true, request.MenuKey);

                        if (typeof MenuInfo !== "undefined" && MenuInfo != null && typeof MenuInfo.length !== "undefined" && MenuInfo.length > 0)
                        {
                            //still has access
                        }
                        else
                        {
                            //no longer has access, blocked
                            return false;
                        }
                    }
                }
                else
                {
                    //need to find requesterID as it holds the caller's ID, use the caller's MenuKey as permission
                    let ExistingRequestID = Tabs.find(function (item)
                    {
                        return item.RequestID === request.requesterID;
                    });

                    if (typeof ExistingRequestID !== "undefined" && ExistingRequestID != null)
                    {
                        request.MenuKey = ExistingRequestID.RequestMenuKey;
                    }

                    if (typeof request.MenuKey === "number" && request.MenuKey > 0)
                    {
                        let MenuInfo = displayService.getFormInformation(true, request.MenuKey);

                        if (typeof MenuInfo !== "undefined" && MenuInfo != null && typeof MenuInfo.length !== "undefined" && MenuInfo.length > 0)
                        {
                            //still has access
                        }
                        else
                        {
                            //no longer has access, blocked
                            return false;
                        }
                    }
                }
            }

            if (typeof request.MenuKey === "undefined"
                || request.MenuKey === null
                || request.MenuKey <= 0
                || typeof request.name !== "string"
                || request.name === ""
                || typeof request.requestedForm !== "string"
                || request.requestedForm === ""
                || typeof request.jsUrl !== "string"
                || request.jsUrl === ""
                || typeof request.url !== "string"
                || request.url === ""
            )
            {
                return false;
            }
            else
            {
                return true;
            }
        }
    }

    function _FormatRequest(request)
    {
        return {
            //request
            RequestID: broadcastService.guid(),
            RequestName: request.name,//ie. Create Purchase Invoice
            RequestMenuKey: request.MenuKey,
            RequestForm: request.requestedForm, //ie. enterPI
            RequestJS: request.jsUrl, //ie. master/controller/enterPIController.js
            RequestHTML: request.url, //ie. master/view/enterPI.html
            RequestDetail: {
                //all custom param add here to avoid messy request
                action: request.action,
                actionDetail: request.actionDetail,
                createNew: request.createNew,
                isPreLive: request.isPreLive,
                sessionId: request.sessionId,
                transDate: request.transDate,
                gangKey: request.gangKey,
                parameters: request.parameters,
                refresh: request.refresh,
                retryCount: (typeof request.retryCount !== "number" ? 0 : request.retryCount)
            },
            RequestStatus: States.Requested,
            RequestBookmarkData: {
                IsBookmarked: (displayService.getBookmarkInformation(request.MenuKey).length > 0)
            },
            RequestType: request.type,//ie. form/report/search (look for specialform)
            //requester
            Requester: request.requester,
            RequesterID: request.requesterID
        };
    }

    function _InsertNewTab(request)
    {
        broadcastService.broadcast("tabCreation", request);
    }

    function _MarkMissingTabAsClosed()
    {
        let tabstrip = $("#tabstrip").data("kendoTabStrip");

        if (typeof tabstrip !== "undefined" && tabstrip != null)
        {
            for (var i = 0; i < Tabs.length; i++)
            {
                if (Tabs[i].RequestStatus === States.Opened)
                {
                    if (document.querySelectorAll('[data-param="' + Tabs[i].RequestID + '"]').length == 0)
                    {
                        Tabs[i].RequestStatus = States.Closed;
                    }
                }
                //else if (Tabs[i].RequestStatus === States.Requested && app.abcd === true)
                //{
                //    //if hang more than 2 seconds assume dead
                //    if ((+(Date.now()) - (+(Tabs[i].RequestedTimeStamp))) / 1000 > 2)
                //    {
                //        if (document.querySelectorAll('[data-param="' + Tabs[i].RequestID + '"]').length == 0)
                //        {
                //            Tabs[i].RequestStatus = States.Closed;
                //        }
                //    }
                //}
            }
        }
    }

    function _ProcessRequest(_request)
    {
        //check for existence
        let ExistingRequestID = Tabs.find(function (item)
        {
            //block user from spamming if it already in request
            if (item.RequestStatus === States.Opened || item.RequestStatus === States.Requested || item.RequestStatus === States.Hide)
            {
                if (item.RequestJS.includes("/powerbiController.js") && _request.RequestJS.includes("/powerbiController.js"))
                {
                    return true;
                }
                else if (item.RequestForm === "reportviewer" && _request.RequestForm === "reportviewer")
                {
                    if (_request.Requester === "reportMenu")
                    {
                        if (item.RequestMenuKey === _request.RequestMenuKey
                            && item.RequestForm === _request.RequestForm
                            && item.RequestName === _request.RequestName)
                        {
                            return true;
                        }
                    }
                    else
                    {
                        if (item.RequestForm === _request.RequestForm
                            && item.RequestName === _request.RequestName
                            && JSON.stringify(item.RequestDetail.parameters) === JSON.stringify(_request.RequestDetail.parameters))
                        {
                            return true;
                        }
                    }
                }
                else if (item.RequestMenuKey === _request.RequestMenuKey
                    && !item.RequestJS.includes("/powerbiController.js") && !_request.RequestJS.includes("/powerbiController.js")
                    && item.RequestForm !== "reportviewer" && _request.RequestForm !== "reportviewer")
                {
                    //single instance form
                    if (_request.RequestMenuKey === item.RequestMenuKey && displayService.getFormInformation(true).filter(x =>
                    {
                        //universal controller always single instance
                        if ((x.JsControllerUrl ?? "").endsWith("/universalController.js"))
                        {
                            return true;
                        }
                        //master setup always single instance
                        else if ((x.JsControllerUrl ?? "").startsWith("/master/"))
                        {
                            return true;
                        }
                        //system setup always single instance
                        else if ((x.JsControllerUrl ?? "").startsWith("/system/"))
                        {
                            return true;
                        }
                        //display form always single instance
                        else if (((x.JsControllerUrl ?? "").split(/[/ ]+/).pop()).toLowerCase().includes("display"))
                        {
                            return true;
                        }
                        //approve/reject form always single instance
                        else if (((x.JsControllerUrl ?? "").split(/[/ ]+/).pop()).toLowerCase().includes("approval"))
                        {
                            return true;
                        }
                        //void form always single instance
                        else if (((x.JsControllerUrl ?? "").split(/[/ ]+/).pop()).toLowerCase().includes("void"))
                        {
                            return true;
                        }
                        //search form always single instance
                        else if (((x.JsControllerUrl ?? "").split(/[/ ]+/).pop()).toLowerCase().includes("search"))
                        {
                            return true;
                        }
                        //proforma form always single instance
                        else if ((x.DispName ?? "").toLowerCase().includes("proforma"))
                        {
                            return true;
                        }
                        //month-end close form always single instance
                        else if ((x.DispName ?? "").toLowerCase().replaceAll(" ", "").replaceAll("-", "").includes("monthendclose"))
                        {
                            return true;
                        }
                        //posting form always single instance
                        else if ((x.DispName ?? "").toLowerCase().includes("posting"))
                        {
                            return true;
                        }
                        //configuration form always single instance
                        else if ((x.DispName ?? "").toLowerCase().includes("configuration"))
                        {
                            return true;
                        }
                        //e-invoice form always single instance
                        else if (x.MenuKey === 5122)
                        {
                            return true;
                        }

                        return false;
                    }).map(y => y.MenuKey).includes(_request.RequestMenuKey))
                    {
                        return true;
                    }
                    //treat nil the same as undefined
                    else if ((typeof item.RequestDetail.actionDetail === "undefined" || item.RequestDetail.actionDetail === "nil")
                        && (typeof _request.RequestDetail.actionDetail === "undefined" || _request.RequestDetail.actionDetail === "nil"))
                    {
                        return true;
                    }
                    //same action detail
                    else if (typeof item.RequestDetail.actionDetail !== "undefined" && item.RequestDetail.actionDetail !== "nil"
                        && typeof _request.RequestDetail.actionDetail !== "undefined" && _request.RequestDetail.actionDetail !== "nil"
                        && item.RequestDetail.actionDetail === _request.RequestDetail.actionDetail)
                    {
                        return true;
                    }
                    //same action detail but sightly different content
                    else if (typeof item.RequestDetail.actionDetail !== "undefined" && item.RequestDetail.actionDetail !== "nil"
                        && typeof _request.RequestDetail.actionDetail !== "undefined" && _request.RequestDetail.actionDetail !== "nil"
                        && (+(item.RequestDetail.actionDetail.toString().substring(0, item.RequestDetail.actionDetail.toString().indexOf("_")))) != 0
                        && (+(_request.RequestDetail.actionDetail.toString().substring(0, _request.RequestDetail.actionDetail.toString().indexOf("_")))) != 0
                        && (+(item.RequestDetail.actionDetail.toString().substring(0, item.RequestDetail.actionDetail.toString().indexOf("_")))) === (+(_request.RequestDetail.actionDetail.toString().substring(0, _request.RequestDetail.actionDetail.toString().indexOf("_")))))
                    {
                        return true;
                    }
                }
            }

            return false;
        });
        //console.log(JSON.stringify(_request));
        //console.log(JSON.stringify(ExistingRequestID));
        //console.log(JSON.stringify(Tabs));
        if (typeof ExistingRequestID !== "undefined" && ExistingRequestID != null)
        {
            //focus on the tab
            let tabstrip = $("#tabstrip").data("kendoTabStrip");

            if (typeof tabstrip !== "undefined" && tabstrip != null)
            {
                for (var i = tabstrip.items().length - 1; i >= 0; i--)
                {
                    //skip dashboard
                    if (i > 0)
                    {
                        if (tabstrip.contentElement(i) != null
                            && tabstrip.contentElement(i).children[0] != null
                            && tabstrip.contentElement(i).children[0].hasAttribute("data-param") === true)
                        {
                            if (ExistingRequestID.RequestID === tabstrip.contentElement(i).children[0].getAttribute("data-param"))
                            {
                                if (ExistingRequestID.RequestStatus === States.Hide)
                                {
                                    //let request = Tabs[i];
                                    let request = Tabs.find(function (item)
                                    {
                                        return item.RequestID === ExistingRequestID.RequestID;
                                    });
                                    if (typeof request !== "undefined" && request != null)
                                    {
                                        request.RequestStatus = States.Opened;
                                        $(tabstrip.contentElement(i)).show();
                                        $(tabstrip.items()[i]).show();
                                        $rootScope.numOfHideForm -= 1;
                                    }
                                }

                                //Special handle for Knowledgebase and Release Note to always re-open
                                if (tabstrip.select().index() !== i || (_request.RequestMenuKey == 1060 || _request.RequestMenuKey == 708) || _request.RequestJS.includes("/powerbiController.js") || _request.RequestMenuKey == 1096)
                                {
                                    tabstrip.select(i);

                                    //broadcast reinitializeExistingForm event so that form can respond to being re-initialize again with new action detail (reopen form)
                                    //instead, to response to tab being selected, listen to refocusExistingForm event
                                    const clonedRequest = _.clone(_request);
                                    broadcastService.broadcast("reinitializeExistingForm", {
                                        ...clonedRequest,
                                        RequestID: ExistingRequestID.RequestID,
                                        actionDetail: _request.RequestJS.includes("/powerbiController.js") ? JSON.parse(JSON.stringify(_request)) : _request.RequestDetail.actionDetail,
                                    });
                                }

                                //found the tab, break~
                                break;
                            }
                        }
                    }
                }
            }
        }
        else
        {
            if (Tabs.filter(function (item) { return item.RequestStatus === States.Opened || item.RequestStatus === States.Requested; }).length < $rootScope.tabLimit)
            {
                //let timestampedRequest = _.cloneDeep(_request);
                //timestampedRequest.RequestedTimeStamp = Date.now();
                //Tabs.push(timestampedRequest);

                Tabs.push(_request);
                _InsertNewTab(_request);

                displayService.updateAutocomplete();
            }
            else
            {
                //max tab reached
                broadcastService.broadcast("openWinReminder", "tablimit");
            }
        }
    };

    managementService.initializeController = function (listenerName, scope, RequestID)
    {
        if (typeof RequestID === "string" && RequestID !== "")
        {
            let requestIndex = Tabs.findIndex(function (item)
            {
                return item.RequestID === RequestID;
            });

            //so that i can update the value
            let request = Tabs[requestIndex];

            if (typeof request !== "undefined" && request != null)
            {
                //store entire request here in form's scope
                scope.ControlRequest = JSON.parse(JSON.stringify(request));

                //monkey patch in old variable
                scope.uniqueID = scope.ControlRequest.RequestID;
                scope.listenerName = listenerName;
                scope.strUniqueID = scope.ControlRequest.RequestID;
                scope.menuKey = scope.ControlRequest.RequestMenuKey;
                scope.title = scope.ControlRequest.RequestName;
                scope.bookmark = {
                    MenuKey: scope.ControlRequest.RequestMenuKey,
                    name: scope.ControlRequest.RequestName,
                    isBookmarked: scope.ControlRequest.RequestBookmarkData.IsBookmarked,
                    jsUrl: scope.ControlRequest.RequestJS,
                    url: scope.ControlRequest.RequestHTML
                };
                scope.setBookmark = function () { broadcastService.broadcast("setBookmark", scope.bookmark); };
                scope.attachID = scope.ControlRequest.RequestID;

                //special function to update actionDetail
                scope.ControlRequestUpdate = function (detail)
                {
                    if (typeof detail != "undefined" && typeof detail != "function" && typeof detail != "symbol")
                    {
                        if (detail === -1 || detail === 0 || detail === "0" || detail === "-1")
                        {
                            request.RequestDetail.actionDetail = "nil";
                        }
                        else
                        {
                            request.RequestDetail.actionDetail = _.cloneDeep(detail);
                        }
                    }
                };

                //legacy unused item, keep to prevent html from screwing up
                scope.pinOU = true;
                scope.entryMode = true;
                scope.changeMode = function () { };
                scope.changeOU = function () { };

                //configure display information
                scope.langKey = userService.userSetting.langKey;
                scope.CtryVersion = displayService.getCountryInformation();
                scope.label = displayService.getLabelInformation();
                scope.msg = displayService.getMessageInformation();

                //initialize attachment count
                scope.attachmentCount = "";

                if (request.RequestDetail.action !== "destroy")
                {
                    //set read only
                    scope.SetReadOnly = (displayService.getFormInformation(true, request.RequestMenuKey).findIndex(function (formItem)
                    {
                        return formItem.EnableModify === false;
                    }) > -1);

                    //show audit log
                    scope.ShowAuditLog = false
                        || (request.RequestMenuKey === 28); //chart of account

                    //set field that can only be access by selected account ie, OU/Company Setup's Code and Description
                    scope.SetLimitedAccess =
                        (userService.userSetting.ID !== "david.tang@lintramax.com"
                            && userService.userSetting.ID !== "keeyong.chuah@lintramax.com"
                            && userService.userSetting.ID !== "ykchong@lintramax.com");

                    //post usage
                    displayService.postFormUsage(request.RequestName, request.RequestMenuKey);
                }

                //finally call function
                setTimeout(function ()
                {
                    scope.$on("assignBookmark", function (event, data)
                    {
                        if (data.type === "report")
                        {
                            if (typeof scope.loadReportBookmarks === "function")
                            {
                                scope.loadReportBookmarks();
                            }
                        }
                        else
                        {
                            if (scope.ControlRequest.RequestMenuKey === data.MenuKey)
                            {
                                scope.bookmark.isBookmarked = data.value;
                            }
                        }

                        if (scope.$root != null && scope.$root != undefined) { if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') scope.$apply() };
                    });

                    function resize()
                    {
                        broadcastService.broadcast("refreshDashboard", null);
                    }

                    $(window).resize(resize);

                    let debugObject;
                    D(() =>
                    {
                        if (D.forms == null) D.forms = [];
                        debugObject = { listenerName, RequestID, scope };
                        D.forms.push(debugObject);
                    });
                    scope.$on('$destroy', function ()
                    {
                        D(() =>
                        {
                            if (D.forms == null) D.forms = [];
                            D.forms = D.forms.filter(o => o !== debugObject);
                        });
                        $(window).off('resize', resize);
                    });

                    switch (request.RequestDetail.action)
                    {
                        case "transfer":
                            if (typeof scope.addData === "function")
                            {
                                scope.addData(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "read":
                            if (typeof scope.readData === "function")
                            {
                                scope.readData(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "none":
                            if (typeof scope.createData === "function")
                            {
                                scope.createData();
                            }

                            if (typeof scope.createNewEntryFromUQF === "function")
                            {
                                scope.createNewEntryFromUQF();
                            }

                            if (typeof scope.createNewNotificationForm === "function")
                            {
                                scope.createNewNotificationForm();
                            }
                            break;
                        case "auto":
                            if (typeof scope.createNewEntryFromUQF === "function")
                            {
                                scope.createNewEntryFromUQF();
                            }

                            if (typeof scope.populateFromDisplayInterOURcv === "function")
                            {
                                scope.populateFromDisplayInterOURcv(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "test":
                            if (request.RequestType === "search" && typeof scope.readData === "function")
                            {
                                scope.readData(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "destroy":
                            //this should be split out
                            break;
                        case "mockup":
                            if (typeof scope.mockupAsDisplayForm === "function")
                            {
                                scope.mockupAsDisplayForm(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "normal":
                            if (typeof scope.setAsNormalUOF === "function")
                            {
                                scope.setAsNormalUOF();
                            }
                            break;
                        case "dashboardDetails":
                            if (typeof scope.readDashboardData === "function")
                            {
                                scope.readDashboardData(request.RequestDetail.actionDetail);
                            }
                            break;
                        case "proforma":
                            if (typeof scope.readData === "function")
                            {
                                scope.createNewEntryFromProforma(request.RequestDetail.actionDetail);
                            }
                            break;
                        default:
                        //saja
                    }

                    if (scope.$root != null && scope.$root != undefined) { if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') scope.$apply() };
                }, 0);

                request.RequestStatus = States.Opened;

                if (scope.$root != null && scope.$root != undefined) { if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') scope.$apply() };
            }
        }
        else
        {
            console.log("RequestID(data-param) with value (" + RequestID + ") is not passed in by $attr.param!");
            console.log(RequestID);
        }
    };

    managementService.initializeReportController = function (listenerName, scope, RequestID)
    {
        if (typeof RequestID === "string" && RequestID !== "")
        {
            let requestIndex = Tabs.findIndex(function (item)
            {
                return item.RequestID === RequestID;
            });

            //so that i can update the value
            let request = Tabs[requestIndex];

            if (typeof request !== "undefined" && request != null)
            {
                //store entire request here in form's scope
                scope.ControlRequest = JSON.parse(JSON.stringify(request));

                //monkey patch in old variable
                scope.uniqueID = scope.ControlRequest.RequestID;
                scope.listenerName = listenerName;
                scope.strUniqueID = scope.ControlRequest.RequestID;
                scope.menuKey = scope.ControlRequest.RequestMenuKey;
                scope.title = scope.ControlRequest.RequestName;
                scope.bookmark = {
                    MenuKey: scope.ControlRequest.RequestMenuKey,
                    name: scope.ControlRequest.RequestName,
                    isBookmarked: scope.ControlRequest.RequestBookmarkData.IsBookmarked,
                    jsUrl: scope.ControlRequest.RequestJS,
                    url: scope.ControlRequest.RequestHTML
                };
                scope.setBookmark = function () { broadcastService.broadcast("setBookmark", scope.bookmark); };
                scope.attachID = scope.ControlRequest.RequestID;

                //legacy unused item, keep to prevent html from screwing up
                scope.pinOU = true;
                scope.entryMode = true;
                scope.changeMode = function () { };
                scope.changeOU = function () { };

                //configure display information
                scope.langKey = userService.userSetting.langKey;
                scope.CtryVersion = displayService.getCountryInformation();
                scope.label = displayService.getLabelInformation();
                scope.msg = displayService.getMessageInformation();

                setTimeout(function ()
                {
                    switch (request.RequestDetail.action)
                    {
                        case "create":
                            scope.reportName = request.RequestDetail.actionDetail;
                            scope.reportParameters = request.RequestDetail.parameters;

                            if (typeof request.RequestDetail.parameters !== "undefined")
                            {
                                scope.setDataWithParam(request.RequestDetail.parameters, request.RequestDetail.refresh);
                            }
                            else
                            {
                                scope.setData();
                            }
                            break;
                        case "test":
                            let viewer = $(scope.reportviewerID).data("telerik_ReportViewer");
                            viewer.viewMode("PRINT_PREVIEW");
                            viewer.refreshReport();
                            break;
                        default:
                        //saja
                    }

                    let debugObject;
                    D(() =>
                    {
                        if (D.reports == null) D.reports = [];
                        debugObject = { listenerName, RequestID, scope };
                        D.reports.push(debugObject);
                    });
                    scope.$on('$destroy', function ()
                    {
                        D(() =>
                        {
                            if (D.reports == null) D.reports = [];
                            D.reports = D.reports.filter(o => o !== debugObject);
                        });
                    });

                    if (scope.$root != null && scope.$root != undefined) { if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') scope.$apply() };
                }, 0);

                request.RequestStatus = States.Opened;

                if (scope.$root != null && scope.$root != undefined) { if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') scope.$apply() };
            }
        }
        else
        {
            console.log("RequestID(data-param) is not passed in by $attr.param!");
        }
    };

    managementService.terminateController = function (requestID)
    {
        if (typeof requestID === "string" && requestID !== "")
        {
            for (var i = 0; i < Tabs.length; i++)
            {
                if (Tabs[i].RequestID === requestID)
                {
                    Tabs[i].RequestStatus = States.Closed;
                    break;
                }
            }
        }
    };

    managementService.hideController = function (requestID)
    {
        if (typeof requestID === "string" && requestID !== "")
        {
            for (var i = 0; i < Tabs.length; i++)
            {
                if (Tabs[i].RequestID === requestID)
                {
                    Tabs[i].RequestStatus = States.Hide;
                    break;
                }
            }
        }
    };

    managementService.getRequestFormByID = function (requestID)
    {
        if (typeof requestID === "string" && requestID !== "")
        {
            for (var i = 0; i < Tabs.length; i++)
            {
                if (Tabs[i].RequestID === requestID)
                {
                    return Tabs[i].RequestForm;
                }
            }
        }

        return "";
    };

    managementService.getRemainingTabLimit = function ()
    {
        return $rootScope.tabLimit - Tabs.filter(function (item) { return item.RequestStatus === States.Opened || item.RequestStatus === States.Requested; }).length
    }

    return managementService;
}]);